export enum TYPES_OF_OPERATIONS {
  OUTGOING = 'outgoing',
  INCOMING = 'incoming',
}

export enum QUOTE_STATUS_TYPE {
  NEW = 'new',
  AWAIT_OFFERS = 'await_offers',
  PREPARED = 'prepared',
  ACCEPTED = 'accepted',
  COMMUNICATION = 'communication',
  COMPLETED = 'completed',
  CLOSED = 'closed',
}

export enum OFFER_STATUS_TYPE {
  NEW = 'new',
  APPROVED = 'approved',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export enum LINK_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum DECISION_TYPE {
  YES = 'YES',
  NO = 'NO',
}

export enum DECISION_TYPE_RUS {
  YES = 'Да',
  NO = 'Нет',
}

export enum FETCH_METHODS {
  POST = 'POST',
  PUT = 'PUT',
}

export enum BTN_TYPE {
  GENERAL = 'general',
  SECONDARY = 'secondary',
}

export enum STATUS_COLORS {
  ORANGE = 'ORANGE',
  GREY = 'GREY',
  GREEN = 'GREEN',
}

export enum BUTTON_TEXT {
  ACCEPTED = 'Принято',
  REJECTED = 'Отклонено',
  SELECTED = 'Выбрано',
  SELECT_NOW = 'Выбрать',
}

export enum CONTRY_LABELS {
  INCOMING = 'Страна отправителя',
  OUTGOING = 'Страна назначения',
}

export const REJECT = 'REJECT';

export enum COMMISSION_TYPE {
  COMMON = 'common',
  LETTER_CREDIT = 'letter_credit',
}

export enum COMMISSION_TYPE_MSG {
  COMMON = 'Итоговая комиссия без аккредитив',
  LETTER_CREDIT = 'Итоговая комиссия с аккредитивом',
}

export enum ButtonVariant {
  GENERAL = 'general',
  SECONDARY = 'secondary',
  DANGER = 'danger',
}

export enum COMPANY_TYPE {
  CLIENT = 'client',
  AGENT = 'agent',
}

export enum PERSON_TYPE {
  CLIENT = 'client',
  AGENT = 'agent',
  MODERATOR = 'moderator',
}

export enum COMPANY_RATING_API {
  LOW = 'low',
  AVERAGE = 'average',
  HIGH = 'high',
}

export enum LoginErrorType {
  NONE,
  UNAUTHORIZED,
  UNKNOWN,
  USER_DISABLED,
}

export enum COMPANY_STATUS {
  'active',
  'inactive',
}

export enum CHAT_STATUS {
  DISABLE = 'disable',
  DISABLE_ADMIN = 'disable_admin',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  CLOSED = 'closed',
  LOADING = 'loading',
}

export enum DELETE_REASON_TYPE {
  CONTACTS = 'contacts',
  CENSORED = 'censored',
  SPAM = 'spam',
  ANOTHER = 'another',
}

export enum STATUS_LOADING_FILE {
  LOADING = 'loading',
  UPLOADED = 'uploaded',
  ERROR = 'error',
}

export enum ROLE_RUS_LANG {
  MODERATOR = 'Модератор',
  MANAGER = 'Менеджер ЛИНК',
}

export enum CURRENCY_KEYS {
  ISO = 'iso',
  COUNTRYNAME = 'countryName',
  CURRENCY = 'currency',
  SYMBOL = 'symbol',
  DATEFORMAT = 'dateFormat',
  NUMERICCODE = 'numericCode',
}

export enum SENDING_TYPE {
  AGENT_SELECTION = 'agentSelection',
  QUOTATION = 'Quotation',
}

export enum AGENT_ASSIGMENT_TYPE {
  ALL = 'all',
  ONLY = 'only',
}

export enum OVERLAY_TYPE {
  DELETE = 'delete',
  QUOTE_INFO = 'quoteInfo',
  EMPTY = 'empty',
}
