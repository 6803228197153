import mapKeys from 'lodash/mapKeys';
import chatStatusDisabled from '@icons/chat_status_disabled.svg';
import chatStatusComplited from '@icons/chat_status_complited.svg';
import chatStatusRejected from '@icons/chat_status_rejected.svg';
import {
  FileType,
  RequestItemFormType,
  AgentFormType,
  CommentType,
} from '@containers/LINK/LData/lTypes';

import {
  TYPES_OF_OPERATIONS,
  DECISION_TYPE,
  CONTRY_LABELS,
  COMMISSION_TYPE_MSG,
  COMMISSION_TYPE,
  COMPANY_RATING_API,
  COMPANY_TYPE,
  CHAT_STATUS,
  DELETE_REASON_TYPE,
  DECISION_TYPE_RUS,
} from './lEnums';

import { agentPlatform, agentRating, clientPlatform, clientRating } from './TextConstans';

export const LINK_PATH = '/link';
export const MEMBERS = 'members';
export const HOMEPAGE = '/home';
export const COMPANY_PAGES = 'company';
export const CLIENT_PAGES = '/client';
export const AGENT_PAGES = '/agent';

const { OUTGOING, INCOMING } = TYPES_OF_OPERATIONS;
const { YES, NO } = DECISION_TYPE;
const { DISABLE, DISABLE_ADMIN, ACTIVE, COMPLETED, CLOSED, LOADING } = CHAT_STATUS;
const { SPAM, ANOTHER, CENSORED, CONTACTS } = DELETE_REASON_TYPE;

export const termOptions = [
  {
    value: '1',
    label: '3-5 дней',
  },
  {
    value: '2',
    label: 'Более 5 дней',
  },
];

export const optionsIsSberClient = [
  {
    value: DECISION_TYPE.YES,
    label: DECISION_TYPE_RUS.YES,
  },
  {
    value: DECISION_TYPE.NO,
    label: DECISION_TYPE_RUS.NO,
  },
];

export const termOptionsMap = mapKeys(termOptions, 'value');

export const operationTypeOptions = [
  {
    value: OUTGOING,
    label: 'Перевод из РФ (Импорт)',
  },
  {
    value: INCOMING,
    label: 'Получение в РФ (Экспорт)',
  },
];

export const operTypeOptionsExt = {
  [OUTGOING]: {
    labelData: 'Данные Банка-получателя',
    labelBankType: 'Банк-получатель',
  },
  [INCOMING]: {
    labelData: 'Данные Банка-отправителя',
    labelBankType: 'Банк-отправитель',
  },
};

export const operTypesScreen = {
  [OUTGOING]: operationTypeOptions[0].label,
  [INCOMING]: operationTypeOptions[1].label,
};

export const operDataScreen = {
  [OUTGOING]: operTypeOptionsExt[OUTGOING].labelData,
  [INCOMING]: operTypeOptionsExt[INCOMING].labelData,
};

export const operBankTypeScreen = {
  [OUTGOING]: operTypeOptionsExt[OUTGOING].labelBankType,
  [INCOMING]: operTypeOptionsExt[INCOMING].labelBankType,
};

export const clientiFormInitValues: RequestItemFormType = {
  id: '',
  type: OUTGOING,
  country: '',
  amount: '',
  currency: '',
  term: '1',
  unk: '',
  registrationRequired: false,
  productType: '',
  swift: '',
  clientComment: '',
  bankName: '',
  isLetterCreditPayment: DECISION_TYPE.YES,
};

export const agentFormInitValues: AgentFormType = {
  isDealPossible: YES,
  commission: '',
  letterCreditCommission: '',
  agentComment: '',
};

export const agentOtions = [
  {
    value: YES,
    label: 'Смогу провести платеж',
  },
  {
    value: NO,
    label: 'Не смогу',
  },
];

export const contryLabelsObj = {
  [INCOMING]: CONTRY_LABELS.INCOMING,
  [OUTGOING]: CONTRY_LABELS.OUTGOING,
};

export const commentInitValue: CommentType = { comment: '' };

export const accrditiveOptions = [
  {
    value: YES,
    label: 'да',
  },
  {
    value: NO,
    label: 'нет',
  },
];

export const commissionMessageMap = {
  [COMMISSION_TYPE.COMMON]: [COMMISSION_TYPE_MSG.COMMON],
  [COMMISSION_TYPE.LETTER_CREDIT]: [COMMISSION_TYPE_MSG.LETTER_CREDIT],
};

export const userRatingOtions = [
  {
    value: COMPANY_RATING_API.LOW,
    label: 'Низкий',
  },
  {
    value: COMPANY_RATING_API.AVERAGE,
    label: 'Средний',
  },
  {
    value: COMPANY_RATING_API.HIGH,
    label: 'Высокий',
  },
];

export interface Credentials {
  // eslint-disable-next-line camelcase
  refresh_token: string;
  token: string;
}

export const docsData: FileType[] = [
  {
    name: 'Политика конфиденциальности',
    size: 490496,
    href: '/Privacy_police.pdf',
  },
  {
    name: 'Политика в отношении обработки ПДн',
    size: 595968,
    href: '/Processing_police_PD.pdf',
  },
  {
    name: 'Согласие на обработку ПДн',
    size: 244736,
    href: '/Agreement_on_personal_data.pdf',
  },
  {
    name: 'Правила использования сервиса ЛИНК',
    size: 301056,
    href: '/LINK_usage_rules.pdf',
  },
];

export const userRoleMap: Record<COMPANY_TYPE, string> = {
  [COMPANY_TYPE.AGENT]: 'Агент',
  [COMPANY_TYPE.CLIENT]: 'Клиент',
};

export const usersPagination = [100, 500, 1000]; // Варианты пагинации для всех

export const userRatingMap: Record<COMPANY_TYPE, string> = {
  [COMPANY_TYPE.AGENT]: agentRating,
  [COMPANY_TYPE.CLIENT]: clientRating,
};

export const typeCompanyMap: Record<COMPANY_TYPE, string> = {
  [COMPANY_TYPE.AGENT]: agentPlatform,
  [COMPANY_TYPE.CLIENT]: clientPlatform,
};

export const moderPagination = [100, 500, 1000]; // Варианты пагинации для всех

export const paginationDefault = 100; // Значение пагинации по умолчанию

export const NO_POSITION = 'noPosition';

export const chatStatusOption = [
  {
    value: DISABLE,
    label:
      'Уважаемый пользователь, в ближайшее время (работаем с 07:00 до 20:00 МСК) менеджер Сбербанка направит Вам в данный чат последовательность шагов для проведения платежа.',
    imgSrc: chatStatusDisabled,
  },
  {
    value: ACTIVE,
    label: 'Отправьте приветственное сообщение.',
    imgSrc: chatStatusDisabled,
  },
  {
    value: COMPLETED,
    label: 'Платёж успешно проведен.',
    imgSrc: chatStatusComplited,
  },
  {
    value: CLOSED,
    label: 'Платёж отменен.',
    imgSrc: chatStatusRejected,
  },
  {
    value: LOADING,
    label: '',
    imgSrc: chatStatusDisabled,
  },
  {
    value: DISABLE_ADMIN,
    label:
      'Чат закрыт. Откройте чат для того, чтобы клиент и агент могли начать переписку по заявке.',
    imgSrc: chatStatusDisabled,
  },
];

export const chatStatusLabel = {
  [DISABLE]: chatStatusOption[0].label,
  [ACTIVE]: chatStatusOption[1].label,
  [COMPLETED]: chatStatusOption[2].label,
  [CLOSED]: chatStatusOption[3].label,
  [LOADING]: chatStatusOption[4].label,
  [DISABLE_ADMIN]: chatStatusOption[5].label,
};

export const chatStatusImgSrc = {
  [DISABLE]: chatStatusOption[0].imgSrc,
  [ACTIVE]: chatStatusOption[1].imgSrc,
  [COMPLETED]: chatStatusOption[2].imgSrc,
  [CLOSED]: chatStatusOption[3].imgSrc,
  [LOADING]: chatStatusOption[4].imgSrc,
  [DISABLE_ADMIN]: chatStatusOption[5].imgSrc,
};

export const deleteReasonText = {
  [CONTACTS]:
    'Информация удалена модератором. Пожалуйста, не переходите в другие каналы связи, продолжайте общение на платформе.',
  [CENSORED]:
    'Информация удалена модератором. Пожалуйста, соблюдайте правила этикета при общении в чате.',
  [SPAM]: 'Информация удалена модератором. Пожалуйста, воздержитесь от отправки спама в чат.',
  [ANOTHER]: 'Информация удалена модератором.',
};

export const correctFileType: string[] = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/x-pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
];

export const regExpUrlCheck = /(?:^|[^@\.\w-])([a-z0-9]+:\/\/)?(\w(?!ailto:)\w+:\w+@)?([\w.-]+\.[a-z]{2,4})(:[0-9]+)?(\/.*)?(?=$|[^@\.\w-])/im;
export const regExpEmailCheck = /^\S+@\S+\.\S+$/;
